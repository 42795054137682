body,
html {
  font-size: 14px;
  background-color: #e2e9ef;
}

:root {
  --myRed: rgb(255, 99, 132);
}

table thead th {
  padding-left: 10px;
  padding-right: 10px;
}

.simplert--shown.simplert {
  z-index: 10000;
}

.loadingProgress,
.noDataMsgContainer {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reportsLoadingProgress{
position: absolute;
left: 50%;
top:50%;
-webkit-transform:translate(-50%,-50%);
        transform:translate(-50%,-50%)
}
.loaderInsideBtn {
  margin-left: 15px;
  display: inline-block;
}
/*
- react confirm alert library
*/
.react-confirm-alert-overlay {
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.react-confirm-alert-body h1 {
  font-size: 2rem;
}

input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{
  color:#708090 !important
}

input::-webkit-input-placeholder,textarea::placeholder{
  color:#708090 !important
}

input:-ms-input-placeholder{
  color:#708090 !important
}

input::-ms-input-placeholder{
  color:#708090 !important
}

input::-webkit-input-placeholder{
  color:#708090 !important
}

input::placeholder{
  color:#708090 !important
}

input.input_error,
textarea.input_error,
select.input_error {
  border-color: rgb(255, 99, 132) !important;
}

.printInvoiceButton {
  /*margin-top: 10px;*/
}

.pdf-logo {
  margin-bottom: 2rem;
}

span.input_error,div.input_error {
  text-align: right;
  font-size: 0.8rem;
  font-style: italic;
  color: var(--myRed);
}

/*categories css*/
.img_preview_box {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem /*same as form-group*/;
}

.img-preview {
  opacity: 0;
}

.img_preview {
  opacity: 0;
 /* height: 150px;*/
  display: none;
}
.pimg_preview {
  opacity: 0;
 /* height: 150px;*/
  display: none;
}

#img_preview_label {
 height: 250px;
  width: 250px;
  background-color: white;
  -webkit-box-shadow: 1px 1px 1px 1px #ddd;
  box-shadow: 1px 1px 1px 1px #ddd;
  padding: 30px;
  cursor: pointer;
  border-radius: 7px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
#pimg_preview_label {
  height: 100px;
  width: 100px;
  background-color: white;
  -webkit-box-shadow: 1px 1px 1px 1px #ddd;
  box-shadow: 1px 1px 1px 1px #ddd;
  padding: 30px;
  cursor: pointer;
  border-radius: 7px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
 
 }
 input[type='file'] {
  color: transparent;
}
 .img_plat {
  height: 40px;
   width: 250px;
   /* background-color: grey; */
   /* -webkit-box-shadow: 1px 1px 1px 1px #ddd; */
   /* box-shadow: 1px 1px 1px 1px #ddd; */
   border: 1px solid #c9caca;
   padding: 10px;
   cursor: pointer;
   border-radius: 7px;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   position: relative;
 }

.img-upload-icon {
  position: absolute;
  top: 50%;
  left: 45%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 4vw !important;
}

.profilePicDropDown {
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

.profilePicDropDown + .dropdown-menu > .dropdown-item {
  padding: 6px 12px;
  cursor: pointer;
}
.dropdown-itemss {
  padding: 3px 3px;
  cursor: pointer;
  background-color: #708090;   
}
.dropdown-itemss  a:focus {
  background-color: #19d5ff;
}

.dropdown-itemss  a:hover {
  background-color: #2f2f2f;
}
.dropdown-toggle::after {
  vertical-align: 0;
}

.navbar {
  height: 90px;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 1px 1px 1px #ddd;
  box-shadow: 1px 1px 1px 1px #ddd;
}
.navbarr {
  height: 90px;
  background-color: #2945c2;
  -webkit-box-shadow: 1px 1px 1px 1px #ddd;
  box-shadow: 1px 1px 1px 1px #ddd;
}

.img-dp {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
}

.textright img {
  border-radius: 50%;
}

.logo {
  font-size: 1.5rem;

  font-weight: 500;
}

.logo a {
  color: #666666 !important;
}

/*to override styles in light.bootstrap.css*/
.submenu {
   display: none;
   color: 'hotpink',
}
.mainmenu:hover
 .submenu{
   display: block;
   color: 'red',
   
}

.sidebar,
.main-panel {
  height: auto;
}

.main-panel {
  width: calc(100% - 200px);
}

.sidebar, body>.navbar-collapse{
  width: 200px;
}

.sidebar {
  z-index: 1031; /*more that .fixed-top of Bootstrap(1030), but less than .modal-overlay of Bootstrap(1040)*/
}
.sidebar .sidebar-wrapper,
body > .navbar-collapse .sidebar-wrapper {
  padding: 0;
  width: 200px;
  position: fixed;
}

.sidebar .logo,
body > .navbar-collapse .logo {
  /*     padding: 25px 15px 25px 15px !important;*/
  background-color: #ffffff;
  /*    box-shadow: 1px 1px 1px 1px #ddd;*/
  border-bottom: none !important;
 /* position: fixed;*/
  width: 200px;
  height: 90px;
  line-height: 80px;
}

.items {
  margin: 0px;
  padding: 0px;
  list-style: none;
 /* margin-top: 90px;*/
  height: calc(100vh - 90px);
  overflow: auto;
}
.items::-webkit-scrollbar-track {
  background-color: aliceblue;
}
.items::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.items::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
}
/* .items li {} */

.items li a {
  color: white;
  display: block;
  padding: 1.7rem 1.2rem;
  border-bottom: 1px solid #698dc5;
}

.items li a:focus {
  background-color: #3462a6;
}

.items li a:hover {
  background-color: #3462a6;
}

.sidebar-wrapper {
  background-color: #4470b6;
  overflow: hidden;
}

.main-panel > .content {
  margin: 2rem;
  max-width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 2px 2px #ddd;
  box-shadow: 1px 1px 2px 2px #ddd;
  margin-top: 120px;
  padding: 30px 15px;
 /* height: calc(100vh - 120px - 7rem);*/
}



.content-print{
font-size:10px !important;
/* width: 100px !important; */
}

.content-print *{
  font-size:10px !important
  }

.main-panel > .contentvthbtn {
  margin: 2rem;
  max-width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 2px 2px #ddd;
  box-shadow: 1px 1px 2px 2px #ddd;
  margin-top: 120px;
  padding: 30px 15px;
 /* height: calc(100vh - 120px - 6rem);*/
}

.main-panel > .contentvthbtn.no-pagination{
  height: calc(100vh - 120px - 2rem);  
}

.main-panel > .content.content--resturent {
  height: auto;
}

.pagination button {
  margin-left: 5px;
}

/* .pagination .next,.pagination .previous{

} */

.tableofauto {
 /* height: calc(100% - 40px);
  overflow: auto;*/
}

.categorycol {
  color: #1fd4ff;
  font-size: 1.5rem;
  text-transform: capitalize;
  font-weight: 500;
}
.selectcategory {
  color: black;
  /* background-color: #1fd4ff; */
  margin: 10px;
  /* padding: 10px; */
  /* border: none;
  outline: none; */
  /*font-size: 0.8rem;*/
  /* border-radius: 7px;
  cursor: pointer;
  white-space: nowrap; */
}
.selectcategoryType {
  color: black;
  /* background-color: #1fd4ff; */
  margin: 10px;
  /* padding: 10px; */
  /* border: none;
  outline: none; */
  /*font-size: 0.8rem;*/
  /* border-radius: 7px;
  cursor: pointer;
  white-space: nowrap; */
}
.btnaddcategory {
  color: white;
  background-color: #1fd4ff;
  padding: 10px;
  border: none;
  outline: none;
  /*font-size: 0.8rem;*/
  border-radius: 7px;
  cursor: pointer;
  white-space: nowrap;
}
.btndownloadpdf {
  color: white;
  background-color: #1fd4ff;
  padding: 6px;
  border: none;
  outline: none;
  border-radius: 7px;
  cursor: pointer;
  white-space: nowrap;
  margin: 10px;
  float: right;
}
.btnaddimg {
  color: white;
  background-color: #1fd4ff;
  padding: 10px;
  border: none;
  outline: none;
  /*font-size: 0.8rem;*/
  border-radius: 7px;
  cursor: pointer;
  white-space: nowrap;
  margin: 10px;
}

.btnmodaladd {
  color: white;
  background-color: #1fd4ff;
  padding: 9px 30px;
  border: none;
  outline: none;
  font-size: 0.8rem;
  border-radius: 7px;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
}

.plusicon {
  padding: 3px 4px;
  border-radius: 100%;
  background-color: white;
  color: #1fd4ff;
}

.p-10 {
  padding: 10px !important;
}

.modal-form-group {
  margin-bottom: 1.5rem;
}

.modal-form-group label {
  font-size: 0.9rem;
  margin-bottom: 0;
}

.modal-form-group input {
  width: 100%;
  padding: 5px 10px;
  border-radius: 7px;
  border: 1px solid #d1d1d1;
  outline: none;
  background-color: #f5f5f5;
  color: #333;
}

.modal-form-group input::-webkit-input-placeholder {
  color: #d1d1d1;
}

.modal-form-group input:-ms-input-placeholder {
  color: #d1d1d1;
}

.modal-form-group input::-ms-input-placeholder {
  color: #d1d1d1;
}

.modal-form-group input::placeholder {
  color: #d1d1d1;
}

.modal-header {
  -webkit-box-shadow: 0 1px 1px #ddd;
  box-shadow: 0 1px 1px #ddd;
  color: #666666 !important;

  padding-bottom: 15px !important;
}

.modal-title {
  font-size: 1.3rem !important;
  font-weight: 600;
  color: #858585;
}

.closeicon {
  font-size: 1.8rem;
  color: #858585;
}

.categoryText {
  width: 100%;
  padding: 10px 10px;
  height: 100px;
  border-radius: 7px;
  border: 1px solid #d1d1d1;
  outline: none;
  /* margin: 10px 0px; */
  background-color: #f5f5f5;
  color: #929292;
}

.userName {
  font-size: 1rem;
}

.posrel {
  position: relative;
}

.posabs {
  position: absolute;
  left: 30px;
}

.bottomTables {
 overflow: hidden;
 margin: 50px;
 display: flex;
 margin-bottom: 50px;
}
.ordertypeTable1 {
  /* width: 40%;  */
  float: right;
  border-radius: 5px;
  display: flex;
  margin: 30px;
}

.paymentTable {
  border : 1px solid black;
  /* width: 40%;  */
  float: left;
  margin: 30px;
}
.paymentTable  thead{
  border : 1px solid black;
  padding: 5px;
}
.paymentTable > thead > tr > th {
  border : 1px solid black;
  padding: 5px;
}
.paymentTable  tbody{
  overflow: hidden;
  border : 1px solid black;
  padding: 5px;
}
.paymentTable > tbody > tr > td {
  border : 1px solid black;
  padding: 5px;
}
.ordertypeTable {
  /* width: 40%;  */
  float: right;
  border-radius: 5px;
}
.ordertypeTable  th{
  border : 1px solid black;
  padding: 5px;
}
.ordertypeTable >tr > td {
  border : 1px solid black;
  padding: 5px;
}

.table {
  overflow: hidden;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

.table thead {
  background-color: #98a4af;
}

.table > thead > tr > th {
  white-space: nowrap;
  color: white !important;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
  /* text-align: center */
}

.table > thead > tr > th:nth-child(1) {
  text-align: center !important;
}

.table > thead > tr > th.text-centerimp {
  text-align: center !important;
}

.table > tbody > tr > td {
  font-size: 0.9rem;
  text-transform: capitalize;
}

.table > tbody > tr > td:nth-child(1) {
  text-align: center;
}

.table > tbody > tr:nth-child(even) {
  background-color: #f3f3f3;
}

.icon {
  padding-right: 8px;
  cursor: pointer;
}

.actionWidth {
  min-width: 65px;
}

.actionWidth1 {
  min-width: 95px;
}

/*menu items*/
.pagebtn,
.pagination .next a,
.pagination .previous a {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #bec1c5;
  color: white;
  border-radius: 7px;
  margin: 0 0.2rem;
  font-size: 0.9rem;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.pagebtn.active:focus,.pagebtn.active:hover{
  color:white
}

.pagination .next.disabled a,
.pagination .previous.disabled a {
  background-color: #ccc;
  display: none;
}

.order{
  color: #666666;
  font-weight: 600;
}

/*react-pagination no way to not render next-btn if there are no pages at all*/
.pagination .previous + .pagination .next {
  display: none;
}

.pagination{
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

button:focus {
  outline: none;
  /* border: none; */
}

.pagebtn:hover {
  background-color: #4270b8;
  outline: none;
  border: none;
  color: #ffffff;
}

.label-name{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /*margin: 0 5px;*/
  margin-bottom: 0;
}

.label-name input[type=radio]{
  margin: 0 5px;
}

.active {
  /* background-color: #4270b8 !important; */
  background-color: #235096;
}

/*tables*/
.seat_width {
  width: 300px;
}

/*assigned tables*/
.activecol {
  color: #30cd30;
}

.inactivecol {
  color: #e64545;
}

.daterange {
  background-color: #f5f5f5;
  border: 1px solid #c9c9c9;
  text-align: center;
  vertical-align: middle;
  border-radius: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
}

.wspace {
  white-space: nowrap;
  font-size: 1rem;
}

.fb {
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/*orders*/
.orderTitle {
  font-size: 1.4rem;
  color: #666666;
}

.orderTitle i.fa {
  margin-right: 1rem;
  cursor: pointer;
}

.orderhding {
  font-size: 1.2rem;
  color: #19d5ff;
}

.ordervalue {
  padding: 10px 25px;
  color: black;
  font-size: 1rem;
  font-weight: 500;
}

.guestdetails_key {
 /* -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;*/
  white-space: nowrap;
  color: #898989;
}

.guestdetails_value {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  white-space: nowrap;
  color: #2f2f2f;
}

/*reports*/
.reportmargin {
  margin-top: 5rem;
}
.showUrban{
text-decoration: underline;
color:#1f74f4 ;
}
/*resturent admin panel*/

.rEdit__editToggle {
  padding: 0;
  background-color: #f5f5f5;
  border-radius: 50%;
  border: 1px solid #c9caca;
  font-size: 1rem;
  color: #bebfbf;
  cursor: pointer;
  height: 2.2rem;
  width: 2.2rem;
  text-align: center;
  border: 1px solid rgb(25, 213, 255);
}
.rEdit__editToggle:focus {
  border: 1px solid rgb(25, 213, 255);
}
.rEdit__editToggle .fa-pencil {
  color: rgb(25, 213, 255);
}

/*rbt-input-main -> react-bootstrap-typeahead*/
.restarantInput,.location-search-input {
  background-color: #f5f5f5;
  border: 1px solid #c9caca;
  padding: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 7px;
}

.restarantInput--disabled {
  border-color: #f5f5f5;
}

.autocomplete-dropdown-container {
  position: absolute;
  background: white;
  z-index: 1;
  border: 1px solid;
}

.suggestion-item {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 6px;
  font-size: 0.9rem;
  border-bottom: 1px solid #efefef;
}

.suggestion-item--active{
  padding: 6px;
  background-color: #c9caca;
}

.restarant_input__labelWrapper {
  /* white-space: nowrap; */
  font-size: 1rem;
}

.restarant_input__labelWrapper label {
  margin-bottom: 0;
  width: 100%;
}

.restarant_input__labelWrapper label span {
  font-weight: bold;
}

.img_radius {
  border-radius: 12px;
}

.fsize {
  font-size: 0.9rem;
  color: #9e9e9e;
  font-weight: 500;
}

.border_bottom {
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 15px;
}

.view_tables {
  text-decoration: underline;
  color: #333333;
/*float: right*/
}

.savebtn {
  padding: 10px 30px;
  background-color: #19d5ff;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  /*float: right;
  display: block;*/
  margin: 1.6rem 0;
  text-transform: uppercase;
  color: #ffffff;
}

.select_Restaurent {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border: 1px solid #c9caca;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 7px;
  color: #9e9e9e;
  margin-right: 15px;
  width:250px;
  max-width: 60%;
  margin-right: 1vw;
}

.select_Restaurent:focus {
  border: none;
  outline: none;
}

.category_icon{
  width:60px;
  height:60px;
  position: relative;
display: -webkit-inline-box;
display: -ms-inline-flexbox;
display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align :center;
      -ms-flex-align :center;
          align-items :center;
  /* border:1px solid #ccc */
  margin-right: 10px;
  vertical-align: middle;
}

.category_icon.selected-icon{
  border-color:#1fd4ff !important;
  outline:#1fd4ff;
  border-width:2px;
}

.category_icon input[type="radio"]{
  height:0;
  width:0;
  -webkit-box-flex:0;
      -ms-flex:0;
          flex:0
}
/* .category_icon img{
  position: absolute;
  top:0;
  left:0
} */
.blckactive {
  background-color: #e8e6e6;
  border: none;
  outline: none;
  border-radius: 7px;
  padding: 8px 25px;
  color: #848484;
}

.tableofauto1 {
  height: calc(100% - 165px);
}

.tableofauto2 {
  height: calc(100% - 80px);
}

.justify {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

/*scroll bar */

.sidebar-wrapper::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.of-auto {
  overflow: auto;
}
.payment-mode-select{
  padding: 6px 10px;
    height: 36px;
    min-width: 100px;
}

.payment-mode-select-wrapper{
  margin-left: auto;
  margin-right: 2vw;
}

/*------ adminProfile.js------*/
#change-pwd__submit{
  /* border-color: #698dc5;
  color:#698dc5 */
  background-color: #1fd4ff;
  color:white;
  border:0;
  outline:none;
}

#change-pwd__submit:active:focus{
  /* background-color:initial */
  outline:none;
}
/*------ ends : adminProfile.js------*/

/*media querys*/
@media (max-width: 767px) {
  .loginForm {
    /*width: 80%;
    height: 58%;*/
  }

  .navbar {
    height: 60px;
  }

  .view_tables {
    margin-top: 20px;
  }
  .main-panel {
    width: 100%;
  }
}

@media (max-width: 576px) {
  html,
  body {
    font-size: 12px;
  }

  .padtop {
    padding-top: 20px;
  }

  .main-panel > .content {
    margin: 1rem;
    margin-top: 75px;
    /*height: calc(100vh - 75px - 2rem);*/
  }

  .main-panel > .contentvthbtn {
    margin: 1rem;
    margin-top: 75px;
   /* height: calc(100vh - 75px - 5rem);*/
  }

  .navbar {
    height: 60px;
  }
  .items {
    height: calc(100vh - 60px);
  }
  .img-dp {
    width: 30px;
    height: 30px;
  }

  .items {
   /* margin-top: 60px;*/
  }

  .wspace {
    font-size: 0.8rem;
  }

  .sidebar .logo,
  body > .navbar-collapse .logo {
    height: 60px;
    line-height: 50px;
  }

  .reportmargin {
    margin-top: 1.8rem;
  }

  .main-panel > .content.content--reports {
    min-height: calc(100vh - 75px - 15px);
    height: auto;
  }

  #img_preview_label {
   /* max-width: 60%;*/
  }

  .img-upload-icon {
    font-size: 8vw !important;
  }

  .select_Restaurent {
  /*  padding: 10px 15px;
    margin-top: 10px;*/
  }

  .btnaddcategory {
    /*margin-top: 10px;
     padding: 10px 15px;*/
  }

  .fb {
    display: none !important;
  }

  .daterange {
    margin: 9px 0px 0px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /*.fixed-top{
        position: fixed !important;
    }*/.main-panel {
  width: 100%;
   }
  .loginForm {
   /* width: 60%;*/
  }

  .navbar {
    height: 60px;
  }

  .main-panel > .content.content--reports {
    min-height: calc(100vh - 120px - 2rem);
    height: auto;
  }
}

@media (min-width: 992px) {
  /*
    .textright {
        float: right;
        position: relative
    }
*/
}

@media (min-width: 576px) {
  .table > thead > tr > th {
    padding: 15px 10px;
    white-space: nowrap;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .reportmargin {
    margin-top: 1.8rem;
  }

  .main-panel > .content.content--reports {
    min-height: calc(100vh - 120px - 2rem);
    height: auto;
  }
}

/*dashboard left menu css*/

@media (max-width: 991px) {
  .nav-open .main-panel {
    -webkit-transform: translate3d(200px, 0, 0) !important;
    transform: translate3d(200px, 0, 0) !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    position: fixed;
  }

  .sidebar {
    left: 0 !important;
    right: auto;
    -webkit-transform: translate3d(-262px, 0, 0) !important;
    transform: translate3d(-262px, 0, 0) !important;
  }

  .nav-open .sidebar {
    -webkit-transform: translate3d(0px, 0, 0) !important;
    transform: translate3d(0px, 0, 0) !important;
  }

  .navbar .navbar-toggler {
    /*width: 37px;
    height: 27px;*/
    position: fixed;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
    left: 10px;
  }
}

@media only screen and (min-width: 1400px) {
  .col-xxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

@media only screen and (min-width: 1500px) {
  .sidebar .sidebar-wrapper,
  body > .navbar-collapse .sidebar-wrapper {
    width: 260px;
  }

  .col-xxl-8{
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .sidebar, body>.navbar-collapse{
    width: 260px;
  }

  .sidebar .logo, body > .navbar-collapse .logo{
    width: 260px;
  }

  .main-panel {
    width: calc(100% - 260px);
  }
}
div.show-image {
  position: relative;
  float:left;
  margin:5px;
}
div.show-image:hover label{
  opacity:0.5;
}
div.show-image:hover img {
  display: block;
}
div.show-image img {
  position:absolute;
  display:none;
}
div.show-image img.img-fluid {
  top:50%;
  left:50%;
}
