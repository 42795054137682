.loginBg {
   /*width: 100%;
    height: 800px;
    position: relative;*/
}

.loginForm {
  width: 55%;
    /* height: 55%;*/
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
   /* background-color: #ffffff;
    border-radius: 10px;
    -webkit-box-shadow: 3px 2px 2px 2px #c4c9cd;
            box-shadow: 3px 2px 2px 2px #c4c9cd;*/
}



.loginput {
    width: 100%;

    padding: 8px 10px;
    border-radius: 7px;
    border: 1px solid #888888;
    outline: none;
    /* margin: 10px 0px; */
}

.loginBtn {
    padding: 12px 45px;
    color: white;
    background-color: #1fd4ff;
    outline: none;
    border: none;
    border-radius: 7px;
    -webkit-box-shadow: 2px 2px 2px 1px #c4c9cd;
            box-shadow: 2px 2px 2px 1px #c4c9cd;
    margin-top: 25px;
    cursor: pointer;
}

.loginBtn:hover {
    outline: none;
    border: none;

}

.colred {
    color: red;
    text-decoration: underline;
}

.font-bold {
    font-weight: 500;
}


@media screen and (max-width:768px){
    .loginForm {
        width: 90%;
        margin: 0 auto;
        /*min-height: 58%;
        top: 10%;
        left: 2%;
        -webkit-transform: none;
                transform: none;*/

    }
}